.navbar {
    z-index: 11111;
    padding: 5px!important;
}

.bg-st-primary {
    background-color: #00008C!important;
}

.sticky {
    top: 0;
    position: fixed;
    right: 0;
    left: 0;
}

.navbar-brand {
    color: white!important;
    transition: 0.5s;
}

.nav-link:active {
    background: white;
}

.nav-link:hover {
    transform: scale(1.2);
}

.nav-marg {
    margin-left: 5%!important;
}

@media(min-width: 992px) {
    .st-mobile {
        display: none!important;
    }
    .st-desct {
        display: block!important;
    }
    .nav-link {
        color: white!important;
        transition: 0.5s;
        padding-right: 15px!important;
        padding-left: 15px!important;
    }
}

@media(max-width: 991px) {
    .st-mobile {
        display: block!important;
        z-index: 1111111;
    }
    .st-desct {
        display: none!important;
    }
    .st-mobile2 {
        z-index: 1111111111111111;
        /* margin-top: -20px; */
        position: fixed;
        top: 0;
        /* right: 0; */
        bottom: 0;
        left: 0;
        width: 90%;
        text-align: center;
        box-shadow: 0 0 10px #00000066;
    }
    .st-www {
        width: 90%;
    }
    .nav-link {
        color: white!important;
        transition: 0.5s;
        padding-right: 0px!important;
        padding-left: 0px!important;
    }
}

.nav-pills .nav-link2 {
    display: block;
    padding: .5rem .7rem!important;
}

.nav-fill .nav-item {
    /* flex: 1 1 auto; */
    text-align: center;
}

.st-zero {
    transform: scale(1.1);
}

.st-360 {
    transform: scale(1.3);
}

.st-ccc {
    font-weight: 700;
    color: #00adff!important;
}

.st-shadow2 {
    box-shadow: 0 0 9px #000000a6!important;
}

.st {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.st li {
    float: none;
    display: inline-grid;
}

.st li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.st li a:hover:not(.active) {
    background-color: white;
}

.st {
    display: inline-flex;
    padding-left: 10px;
}

.st-search-icon {
    position: relative;
    top: 0;
    margin-top: -23px;
    float: right;
    margin-right: 10px;
    cursor: pointer;
}

.st-nav-a {
    padding: 14px 10px!important;
}

.st-nav-a2 {
    padding: 14px 6px!important;
}

.st-20 {
    width: 20%!important;
}

.st-40 {
    width: 40%!important;
}

.st-sss {
    width: 90%!important;
    margin-left: 5%!important;
}

.st-new-x {
    font-size: 22px!important;
    padding-left: 12%!important;
    text-shadow: 0px 3px 5px #000000c7!important;
}

.st-errorbtn {
    transition: 0.5s;
}

.st-errorbtn:hover {
    transform: scale(1.1);
}

.st-errorbtn:active {
    background: transparent!important;
    color: #00008C!important;
}