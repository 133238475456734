.st-btn {
    width: 47px;
    height: 47px;
}

.st-img-h {
    transition: 0.5s;
    font-size: 25px;
    vertical-align: middle;
    color: #696971;
}

.st-img-h:hover {
    /* animation: App-logo-spin infinite 20s linear; */
    transform: scale(1.5)!important;
}