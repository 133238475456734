#sidebar ul li a {
    font-size: 0.9em;
}

#main a {
    text-decoration: underline !important;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
}

.st-list {
    list-style-type: none;
}

@media only screen and (max-width: 2000px) and (min-width: 1300px) {
    #sidebar {
        width: 200px;
        position: fixed;
        top: 50px;
        left: 0;
        height: 100vh;
        z-index: 999;
        background: #efefef;
        color: #fff;
        transition: all 0.3s;
        /* box-shadow: 0 0 6px #0000008c; */
        border-right: 1px solid #e6e4e4;
    }
    #sidebar2 {
        width: 350px;
        position: fixed;
        top: 50px;
        right: 0;
        height: 100vh;
        z-index: 999;
        background: #efefef;
        color: #fff;
        transition: all 0.3s;
        /* box-shadow: 0 0 6px #0000008c; */
        border-left: 1px solid #e6e4e4;
    }
    #content {
        width: calc(100% - 200px - 350px);
        padding: 10px;
        min-height: 100vh;
        transition: all 0.3s;
        position: absolute;
        top: 72px;
        left: 200px!important;
    }
}

@media only screen and (max-width: 1290px) and (min-width: 1100px) {
    #sidebar {
        width: 200px;
        position: fixed;
        top: 50px;
        left: 0;
        height: 100vh;
        z-index: 999;
        background: #efefef;
        color: #fff;
        transition: all 0.3s;
        /* box-shadow: 0 0 6px #0000008c; */
        border-right: 1px solid #e6e4e4;
    }
    #sidebar2 {
        width: 290px;
        position: fixed;
        top: 50px;
        right: 0;
        height: 100vh;
        z-index: 999;
        background: #efefef;
        color: #fff;
        transition: all 0.3s;
        /* box-shadow: 0 0 6px #0000008c; */
        border-left: 1px solid #e6e4e4;
    }
    #content {
        width: calc(100% - 200px - 280px);
        padding: 10px;
        min-height: 100vh;
        transition: all 0.3s;
        position: absolute;
        top: 72px;
        left: 200px!important;
    }
}

@media only screen and (max-width: 1099px) and (min-width: 992px) {
    #sidebar {
        width: 180px;
        position: fixed;
        top: 50px;
        left: 0;
        height: 100vh;
        z-index: 999;
        background: #efefef;
        color: #fff;
        transition: all 0.3s;
        /* box-shadow: 0 0 6px #0000008c; */
        border-right: 1px solid #e6e4e4;
    }
    #sidebar2 {
        width: 250px;
        position: fixed;
        top: 50px;
        right: 0;
        height: 100vh;
        z-index: 999;
        background: #efefef;
        color: #fff;
        transition: all 0.3s;
        /* box-shadow: 0 0 6px #0000008c; */
        border-left: 1px solid #e6e4e4;
    }
    #content {
        width: calc(100% - 180px - 240px);
        padding: 10px;
        min-height: 100vh;
        transition: all 0.3s;
        position: absolute;
        top: 72px;
        left: 175px!important;
    }
}

@media(max-width: 991px) {
    #sidebar2 {
        display: none;
        transition: 0.5s;
        visibility: hidden;
    }
    #sidebar {
        display: none;
        transition: 0.5s;
        visibility: hidden;
    }
    #content {
        margin-top: 45px!important;
    }
}

.st-border {
    border-color: #dadada!important;
}

#sidebar.active {
    margin-left: -250px;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar ul.components {
    padding: 20px 0px 0px 0px;
    /* border-bottom: 1px solid #47748b; */
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 1px 5px 1px 5px;
    font-size: 1em!important;
    display: block;
}

#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

#sidebar2.active {
    margin-right: -250px;
}

#sidebar2 .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar2 ul.components {
    padding: 0px 0px 0px 0px;
    /* border-bottom: 1px solid #47748b; */
}

#sidebar2 ul p {
    color: #fff;
    padding: 10px;
}

#sidebar2 ul li a {
    padding: 2px 0px 2px 10px;
    font-size: 1em!important;
    display: block;
}

#sidebar2 ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar2 ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */


/* @media(min-width: 789px) {
    #content {
        left: 200px;
    }
} */

#content.active {
    width: 100%;
}


/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    /* #sidebar {
        margin-left: -200px;
    } */
    #sidebar.active {
        margin-left: 0;
    }
    #content {
        width: 100%;
    }
    /* #content.active {
        width: calc(100% - 200px - 350px);
    } */
    #sidebarCollapse span {
        display: none;
    }
}

@media (max-width: 768px) {
    #sidebar2 {
        margin-right: -350px;
    }
    #sidebar2.active {
        margin-left: 0;
    }
    #content2 {
        width: 100%;
    }
    #content2.active {
        width: calc(100% - 350px);
    }
    #sidebarCollapse span {
        display: none;
    }
}

.side-head {
    padding: 1px 5px 1px 5px;
    color: #121235;
}


/* .st-scroll {
    overflow: scroll;
} */

.st-scroll {
    /* height: 290px!important; */
    height: 100vh!important;
    overflow-y: auto;
}


/* width */

.st-scroll::-webkit-scrollbar {
    width: 4px;
}


/* Track */

.st-scroll::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px #80808030; */
    border-radius: 10px;
}


/* Handle */

.st-scroll::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
}


/* Handle on hover */

.st-scroll::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

.st-simg {
    width: 30px;
    height: 30px;
    border: 1px solid #f6f6f7;
    border-radius: 50%;
    box-shadow: 0 0 3px #00000066;
}

@media(min-width: 768px) {
    .st-mobile {
        display: none!important;
    }
    .st-desct {
        display: block!important;
    }
}

@media(max-width: 767px) {
    .st-mobile {
        display: block!important;
    }
    .st-desct {
        display: none!important;
    }
}