.modal {
    font-size: 12px;
}

.modal>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.popup-content {
    background: #ded8d8!important;
    border: 0px!important;
    width: 43%!important;
    border-radius: 3px;
    padding: 2px!important;
}

.st-40 {
    width: 40%!important;
}

@media(max-width: 767px) {
    .st-mobile-grid {
        transition: 0.5s;
        width: 50%!important;
    }
    .popup-content {
        width: 85%!important;
    }
    .st-calcel {
        display: block;
        position: fixed;
        height: 25px;
        text-align: center;
        width: 25px;
        top: 0;
        right: 0;
        color: red;
        z-index: 111111111111!important;
        margin-right: 5%;
        border-radius: 100%;
        border: 1px solid white;
        box-shadow: 0 0 10px #00000073;
        text-shadow: 0 0 2px #00000096;
    }
}

@media(min-width: 769px) {
    .st-calcel {
        display: none;
    }
}

.st-calcel:active {
    background-color: red;
    color: white;
}