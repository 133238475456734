.st-empty2 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.st-empty {
    padding-top: 9px;
    padding-bottom: 9px;
}

.st-bg {
    background: #00008c!important;
    color: white!important;
}

.st-bg-d {
    background: #00008c!important;
    color: white!important;
}

.footer2 {
    position: fixed;
    bottom: 0;
    border-radius: 0px!important;
    left: 0;
    right: 0;
    background: black!important;
}

.radius {
    border-radius: 5px!important;
}

.st-cccz {
    font-weight: 700;
    color: black!important;
}

.st-cccz2 {
    color: #00adff!important;
}