body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #efefef!important;
}

body {
    height: 290px;
    overflow-y: auto;
}


/* width */

body::-webkit-scrollbar {
    width: 4px;
}


/* Track */

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #80808030;
    border-radius: 10px;
}


/* Handle */

body::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
}


/* Handle on hover */

body::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

a {
    transition: 0.3s!important;
}

a:hover {
    text-decoration: none!important;
    color: #050538!important;
}

.Toastify__toast-container--top-right {
    z-index: 11111111111!important;
}

.Toastify__toast--success {
    background: green!important;
}

.st-thumbnail {
    height: 183px!important;
    width: fit-content!important;
}

.popup-overlay {
    z-index: 99999999!important;
}

.popup-content {
    background: #ded8d8!important;
    box-shadow: 0 0 10px #0000004a!important
}

.st-mouse-hover {
    transition: 0.5s;
}

.st-mouse-hover:hover {
    cursor: grab!important;
}

@media(min-width: 678px) {
    .st-card-height {
        min-height: 385px!important;
    }
    .st-fixed-footer {
        bottom: 0!important;
        position: fixed!important;
        width: inherit!important;
        z-index: 111111;
        border-radius: 0px!important;
    }
    .st-fff {
        padding-left: 10%!important;
    }
}

.navbar-collapse {
    flex-grow: 0!important;
}

.react-autocomplete-input {
    margin-top: 20px;
    width: 95%;
    border-radius: 4px;
}

.react-autocomplete-input>li.active {
    background-color: #00008c!important;
    color: #fff;
}

.search_img {
    width: 100px;
    height: 100px;
}

.st-search {
    transform: 0.1s;
}

.st-search:hover {
    background-color: #007bff!important;
}

.st-active {
    background-color: #007bff!important;
    transform: scale(1.1);
}

@media(max-width: 991px) {
    .st-search-30 {
        width: 30%!important;
    }
    .st-search-70 {
        width: 70%!important;
    }
    .st-fixed-footer {
        margin-top: 10rem!important;
        /* bottom: 0!important;
        position: fixed!important; */
        width: inherit!important;
        z-index: 111111;
        border-radius: 0px!important;
    }
    .search-empty {
        padding-top: 3rem;
    }
}

@media(max-width:991px) AND (min-width:555px) {
    .st-search-50 {
        width: 50%!important;
    }
}

.st-scroller {
    border-radius: 50%;
    min-width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(33, 37, 41, .1), 0 7px 14px rgba(33, 37, 41, 0.5);
    font-size: 27px;
    position: fixed;
    bottom: 0;
    z-index: 1111111111111;
    right: 0;
    margin-bottom: 20px;
    background: #00008c;
    color: white;
    margin-right: 20px;
    transition: 0.5s;
    cursor: pointer;
}

.st-scroller:hover {
    background: white;
    color: #00008c;
    transform: scale(1.1);
}

.st-scroller_icon {
    transition: 0.5s;
}

.st-scroller:active {
    transform: scale(1.3);
}

.st-bg-dark {
    background-color: #26282b!important;
}