.card {
    border: 1px solid rgba(0, 0, 0, .125)!important;
    box-shadow: 0 0 5px #00000014!important;
    transition: 0.3s;
}

.card:hover {
    box-shadow: 1px 0px 11px #00000026!important;
}


/* .img-thumbnail {
    box-shadow: 1px 3px 4px #0000001c;
} */

.card-img-overlay {
    margin-right: -29px;
    z-index: 1111;
    padding: .0.2rem!important;
}

.st-h-rotate {
    transition: 0.5s;
}

.st-h-rotate:hover>img {
    animation: App-logo-spin infinite 20s linear;
}

.st-card-icon {
    transition: 0.5s;
    color: #696971;
}

.st-card-icon:hover {
    /* animation: App-logo-spin infinite 20s linear; */
    transform: scale(1.5)!important;
}

.related-sha {
    box-shadow: 1px 0px 11px #00000026!important;
}

@media(max-width: 767px) {
    .st-mobile-grid {
        transition: 0.5s;
        width: 50%!important;
    }
    .content1 {
        margin-top: 4px!important;
    }
    .mobile-space {
        margin-top: 20px!important;
    }
    .st-thumbnail-2 {
        /* height: 355px!important; */
        width: -moz-fit-content!important;
        width: fit-content!important;
    }
}

@media(min-width: 768px) {
    .st-thumbnail-2 {
        height: 355px!important;
        width: -moz-fit-content!important;
        width: fit-content!important;
    }
}

.st-clickbtn {
    background: transparent!important;
    padding: 0px!important;
    border: 0px!important;
}

.st-top-right {
    position: absolute;
    top: 8px;
    right: 16px;
    cursor: pointer;
}

.st-color {
    color: #00008c;
}

.st-dropdown {
    position: relative;
    display: inline-block;
}

.st-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: -143px;
}

.st-dropdown-content a {
    color: black;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
}

.st-dropdown-content a:hover {
    background-color: #f1f1f1
}

.st-dropdown:hover .st-dropdown-content {
    display: block;
}

.st-dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.st-click {
    transition: 0.5s;
}

.st-click:active {
    color: red;
}